import { useState, useEffect} from "react";
import TextField from '@mui/material/TextField';

export default function AddressSearch({ clearAddressSearchText, setClearNpiSearchText, setAddressSearchText, setSearchResults, setClearAddressSearchText }) {
    const [input, setInput] = useState("");
    
    function handleSubmit(e) {
      e.preventDefault();
      setSearchResults(null);  
      setAddressSearchText(input);
      setClearNpiSearchText(true);

    }
  
    function handleChange(e) {

      setInput(e.target.value);
      setSearchResults(null);  
      setClearNpiSearchText(true);
    }



    useEffect(()=>{
      if( clearAddressSearchText){
        setInput("");
        setAddressSearchText("");
        setClearAddressSearchText(false);
      }
  }, [clearAddressSearchText]); // empty array means only once


    return (
      <div>
        <form onSubmit={handleSubmit}>
        
          <TextField
            value={input}
            variant="outlined"
            onChange={handleChange}
            placeholder="Enter a street address"
            id="searchText"
            label="Street Address"
          />
        </form>
      </div>
    );
  }