import * as React from 'react';
import { useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function SearchResults(props) {


    let  results;
    let searchStatus;
  //  if (props.searchResults !== undefined){
  
  //useEffect(() => {
    //alert(props.searchResults);
    if (props.searchResults !== null){
      //console.log("searchResults type: " + typeof(props.searchResults));
      //console.log("body=" + props.searchResults.body);
      
      //console.log("props.searchResults.body type: " + typeof(props.searchResults.body));
      if (typeof props.searchResults.body === 'string') {
        results = JSON.parse(props.searchResults.body);
        //console.log("results=" + JSON.stringify(results));
        console.log("***SearchResults=" + props.searchResults.body);
        searchStatus = results.moreInfo;
      }
    }
  //}, [props.searchResults]);

    console.log("AddressSearchResults: " + props.addressSearchResults);
    console.log("results: " +results);
  
    return (
      <div>
      {props.searchResults  && results && results.inDirectory? (

      <Card variant="outlined" sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
              {results.npi}
              </Typography>
              <Typography variant="h5" component="div">
              {results.ProviderFirstName} {results.ProviderLastName}
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{results.directAddresses.map((directAddress) => <li>{directAddress}</li>)}</Typography>
            </CardContent>
          </Card>      
        ) : (
          <Card sx={{ minWidth: 275 }}>
            <Typography sx={{ color: 'common.red', mb: 1.5 }}>{searchStatus}</Typography>
          </Card>
        )
      }
      </div>
    );
  
  }
  