import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import { MaplibreLegendControl } from "@watergis/maplibre-gl-legend";
import '@watergis/maplibre-gl-legend/dist/maplibre-gl-legend.css'

import 'maplibre-gl/dist/maplibre-gl.css';
import './MLMap.css';

/*

  https://docs.maptiler.com/react/maplibre-gl-js/how-to-use-maplibre-gl-js/
  https://visgl.github.io/react-map-gl/docs/get-started

npm start
*/

export default function MLMap({lat, lng, servDirectAddr, range, specialty, servDesc, setCloseProviders, setCloseProviderSpecialties}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [providerLocations, setProviderLocations] = useState("");
  const count = "3500";  // How many providers to return

  const [zoom] = useState(13); 
  //const [API_KEY] = useState('YLHUognKTQe3hQ3Au6oK'); // eric.mears@directtrust.org
  const [API_KEY] = useState('1AEYXzQDMoWRR1lGq6bj'); // ericmmears@gmail.com

//https://github.com/watergis/maplibre-gl-legend/tree/main/packages/maplibre-gl-legend
// npm i -D @watergis/maplibre-gl-legend
  const targets = {
    'pipeline': 'Pipeline',
    'pipeline_annotation': 'Pipeline Label', 
    'meter': 'Water Meter',
    'flow meter': 'Flow Meter', 
    'valve': 'Valve', 
    'firehydrant': 'Fire Hydrant', 
    'washout': 'Washout',
    'tank': 'Tank', 
    'tank_annotation': 'Tank Label', 
    'wtp': 'WTP', 
    'wtp_annotation': 'WTP Label', 
    'intake': 'Intake', 
    'intake_annotation': 'Intake Label', 
    'parcels': 'Parcels', 
    'parcels_annotation': 'Parcels Label', 
    'village': 'Village', 
    'village_annotation': 'Village Label', 
    'dma': 'DMA',
    'dma_annotation': 'DMA Label', 
  };



  /* https://codepen.io/bothness/pen/ExgwzEG */
  const style = {
    "version": 8,
    "sources": {
      "osm": {
        "type": "raster",
        "tiles": ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
        "tileSize": 256,
        "attribution": "&copy; OpenStreetMap Contributors",
        "maxzoom": 19
      }
    },
    "layers": [
      {
        "id": "osm",
        "type": "raster",
        "source": "osm" // This must match the source key above
      }
    ]
  };

  useEffect(() => {
   


    if(specialty !== undefined && specialty !== null){
      console.log("specialty = " + specialty.code);
    }
    if(servDesc !== undefined && servDesc !== null){
      console.log("servDesc = " + servDesc.code);
    }   

    if ( typeof(lat) == "undefined" || lat == null ) 
    {
      //console.log("lat is unusable, bailing...");
      return;
    }
    if ( typeof(lng) == "undefined" || lng == null ) 
    {
        //console.log("lng is unusable, bailing...");
        return;
    }


/*
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      center: [lng, lat],
      zoom: zoom
    });  
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://demotiles.maplibre.org/style.json`,
      center: [lng, lat],
      zoom: zoom
    });
  
 

        map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://openmaptiles.github.io/osm-bright-gl-style/style-cdn.json',
      center: [lng, lat],
      zoom: zoom
    });



  */
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: style,
      center: [lng, lat],
      zoom: zoom
    });

    
     // add legend control with all layers, and it reverse layer order
     map.current.addControl(new MaplibreLegendControl(targets, {reverseOrder: false, onlyRendered: false}), 'bottom-left');


    new maplibregl.Marker({ color: "#FF0000" })
    .setLngLat([lng, lat])
    .setPopup(new maplibregl.Popup().setHTML(servDirectAddr))
    .addTo(map.current);

    map.current.addControl(new maplibregl.NavigationControl(), 'top-right');



      fetch("https://aocxx6m69i.execute-api.us-east-2.amazonaws.com/v1/provider-location-search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "IZ44pNgw2i3maM97akCrG61vPgAwwuWh72gcJewv"
        },
        body: JSON.stringify({
          latitude: lat,
          longitude: lng,
          range: range,
          count:count
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {

          let bdy = JSON.parse(data.body);
          let locationRecords = bdy.locationRecords;
          console.log(locationRecords.length + " providers were returned.")
          let filteredRecords = [];
          if( specialty !== undefined && specialty !== null){
            locationRecords.forEach(item =>{
              if( item.provSpecCode === specialty.code)
                filteredRecords.push(item);
            })
            setProviderLocations(filteredRecords);
          } else 
          {
            setProviderLocations(locationRecords);
          } 

          setCloseProviders(locationRecords);

          //console.log("Map: useEffect: got provider-location-search: " + data.body);

        })

  }, [API_KEY, lng, lat, zoom, range, servDesc, servDirectAddr, specialty]);

  if( providerLocations && providerLocations.length > 0){
    //console.log("locationRecords=" + providerLocations);

    let locationHashMap = new Map();
    let closeSpecialties = [];

    /* this is the search target marker */
    let marker = {
      "color" : "#FF0000",
      "latitude" : lat,
      "longitude" : lng,
      "popupHtml" : servDirectAddr,
      "count" : 1,
      "orgs" : [],
      "target": 1
    }
    let initial_marker = {
      "latitude" : lat,
      "longitude" : lng,
      "popupHtml" : 1,
      "count" : 1,
      "orgs" : []
    }
    // Load up with the initial marker
    let cluster_precision = 4
    locationHashMap.set(parseFloat(lat).toFixed(cluster_precision), marker);

    providerLocations.forEach(item =>{

        if( specialty !== undefined && specialty !== null){
          // we have a filter request
          if(item !== null && item.provSpecCode !== specialty.code){
            return;
          }
        }
        let mapLat = parseFloat(item.latitude).toFixed(cluster_precision)
        let initial_marker = {
          "latitude" : item.latitude,
          "longitude" : item.longitude,
          "popupHtml" : 1,
          "count" : 1,
          "orgs" : []
        }
        /* let's consolidate addresses in the same location */
        let current_marker = locationHashMap.get(mapLat)
        if (current_marker !== undefined){
            /* There is already an address here */
            current_marker.count += 1
            if(item.orgName.length > 0 &&  !current_marker.orgs.includes(item.orgName) && !current_marker.orgs.includes("<br>" + item.orgName)){
              /* this is a new organization */
              current_marker.orgs.push("<br>" + item.orgName)
            }
            if( current_marker.orgs.length > 0){
              /* if we have orgs, log the number of them and the org names */
              current_marker.popupHtml = "(" + current_marker.count + ")\n" + current_marker.orgs.toString()
            }
            locationHashMap.set(parseFloat(item.latitude).toFixed(cluster_precision), current_marker );
        } else {
          /* this address doesn't already have a marker */
          if(item.orgName.length > 0){
            /* if there is a org name, save it */
            initial_marker.orgs.push(item.orgName)
            initial_marker.popupHtml = "(" + initial_marker.count + ")\n" + initial_marker.orgs.toString()
          }  
          locationHashMap.set(parseFloat(item.latitude).toFixed(cluster_precision), initial_marker);
        }
        if (item.provSpecText !== undefined) {
          let specialty = {
            "code" : item.provSpecCode,
            "display" : item.provSpecText
          }
          if(!closeSpecialties.some( closeSpecialty => closeSpecialty['code'] === specialty.code )) {
            closeSpecialties.push(specialty);
          }
        }
    })
    //setCloseProviderSpecialties(closeSpecialties);
    //locationHashMap.set(parseFloat(lat).toFixed(2)+parseFloat(lng).toFixed(2), "base location");
    

   

    locationHashMap.forEach(item =>{
      let markercolor="#3374ff" // blue

      if( item.count >= 1 && item.count < 10){
        markercolor="#3374ff"
      }
      if( item.count >= 10 && item.count < 100){
        markercolor="#00FF7F" // green
      }   
      if( item.count >= 100 && item.count < 1000){
        markercolor="#40E0D0" // turquoise
      }  
      if( item.count >= 1000 ){
        markercolor="#FF00FF" // magenta
      }      
      if( item.target !== undefined){
        markercolor = "#FF0000" // red
      }    
      new maplibregl.Marker({ color: markercolor })
      .setLngLat([item.longitude, item.latitude])
      .setPopup(new maplibregl.Popup().setHTML(item.popupHtml))
      .addTo(map.current);
    })
       
  }


  if( map.current){
  map.current.on('load', () => {

  })};


  if (map.current) {
   
    
  }
  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}

