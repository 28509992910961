import { useState, useEffect} from "react";
import TextField from '@mui/material/TextField';

export default function NpiSearchText({ searchResults, clearNpiSearchText, setNpiSearchText, setClearAddressSearchText, setSearchResults, setClearNpiSearchText }) {
    const [input, setInput] = useState("");
    const [inDirectory, setIndirectory] = useState(true);
    const [helperText, setHelperText] = useState("Enter a direct address or npi");

    function handleSubmit(e) {
      e.preventDefault();

      setSearchResults(null); 
      setNpiSearchText(input);
      setClearAddressSearchText(true); 

    }
  
    function handleChange(e) {

      setInput(e.target.value);
      setClearAddressSearchText(true); 
    }



    useEffect(()=>{

      if( searchResults != null){
        if( searchResults.body != undefined){
          let searchResultsObject = JSON.parse(searchResults.body);
          if( searchResultsObject.inDirectory){
            setIndirectory(true);
            setHelperText("Enter a direct address or npi");
          } else {
            setIndirectory(false);
            setHelperText("Not found in the directory");
          }
        }
      }
      if( clearNpiSearchText){
        setInput("");
        setNpiSearchText("");
        setClearNpiSearchText(false);
      }
  }, [clearNpiSearchText,searchResults]); // empty array means only once
  


    return (
      <div>
        <form onSubmit={handleSubmit}>
        
          <TextField
            required
            error={!inDirectory}
            value={input}
            variant="outlined"
            onChange={handleChange}
            placeholder="Enter direct address or npi"
            id="searchText"
            label="Direct address/NPI"
            helperText={helperText}
          />
        </form>
      </div>
    );
  }