import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ServDescSelect({setServDesc}) {


  return (
    <Autocomplete
      id="country-select-demo"
      onChange={(event, newServDesc) => {
        setServDesc(newServDesc);
      }}
      sx={{ width: 300 }}
      options={servDescs}
      autoHighlight
      getOptionLabel={(option) => option.display}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            {option.display} ({option.code})
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a service description"
          slotprops={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
        />
      )}
    />
  );
}
//https://objects.directtrust.org/standards/terminology/codeSystem/ServDescCS.json
const servDescs = 
[
    {
        "code": "360x-data-exchange",
        "display": "information exchange between 360x participants"
    },
    {
        "code": "payer-payer-data-exchange",
        "display": "information exchange between payers"
    },
    {
        "code": "payer-provider-data-exchange",
        "display": "information exchange between payers and providers"
    },
    {
        "code": "payer-patient-data-exchange",
        "display": "information exchange between payers and patients"
    },
    {
        "code": "provider-provider-data-exchange",
        "display": "information exchange between providers"
    },
    {
        "code": "provider-payer-data-exchange",
        "display": "information exchange between providers and payers"
    },
    {
        "code": "provider-to-patient-data-exchange",
        "display": "information exchange between providers and patients"
    },
    {
        "code": "patient-payer-data-exchange",
        "display": "information exchange between patients and payers"
    },
    {
        "code": "patient-provider-data-exchange",
        "display": "information exchange between patients and providers"
    },
    {
        "code": "patient-public-health-data-exchange",
        "display": "information exchange between patients and public health"
    },
    {
        "code": "patient-research-data-exchange",
        "display": "information exchange between patients and research"
    },
    {
        "code": "public-health-patient-data-exchange",
        "display": "information exchange between public health and patients"
    },
    {
        "code": "research-patient-data-exchange",
        "display": "information exchange between research and patients"
    },
    {
        "code": "patient-access",
        "display": "information exchange for patients"
    },
    {
        "code": "care-case-manager",
        "display": "information exchange with a care or case managers"
    },
    {
        "code": "care-coordination",
        "display": "information exchange for care coordination across the entire patient care team"
    },
    {
        "code": "advanced-eob",
        "display": "information exchange for advanced eobs"
    },
    {
        "code": "coverage-requirements-discovery-crd",
        "display": "information exchange for discovering coverage requirements"
    },
    {
        "code": "documentation-templates-rules-dtr",
        "display": "information exchange for documentation templates and rules"
    },
    {
        "code": "prior-authorization",
        "display": "information exchange for prior authorization"
    },
    {
        "code": "formulary",
        "display": "information exchange for drug formulary"
    },
    {
        "code": "prescriptions",
        "display": "information exchange for prescriptions"
    },
    {
        "code": "item-service-price-transparency",
        "display": "information exchange for item or service price transparency"
    },
    {
        "code": "patient-cost-transparency-gfe",
        "display": "information exchange for making patient costs transparent"
    },
    {
        "code": "quality-measure-reporting",
        "display": "information exchange for quality measure reporting"
    },
    {
        "code": "adverse-event-reporting",
        "display": "information exchange for adverse event reporting"
    },
    {
        "code": "notifications",
        "display": "information exchange for notifications"
    },
    {
        "code": "adt-notifications",
        "display": "information exchange for ADT notifications"
    },
    {
        "code": "scheduling",
        "display": "information exchange for scheduling"
    },
    {
        "code": "release-of-information",
        "display": "information exchange for release of information"
    },
    {
        "code": "medical-records",
        "display": "information exchange for medical records"
    },
    {
        "code": "administrative",
        "display": "information exchange for administrative activities"
    },
    {
        "code": "support",
        "display": "information exchange for technical or customer support activities"
    },
    {
        "code": "billing",
        "display": "information exchange for billing"
    },
    {
        "code": "referrals",
        "display": "information exchange for referrals"
    },
    {
        "code": "transfer-transition-of-care",
        "display": "information exchange for transfers and transitions of care"
    },
    {
        "code": "provider-directory",
        "display": "information exchange for provider directories"
    },
    {
        "code": "directory-steward",
        "display": "information exchange for directory maintenance and quality"
    },
    {
        "code": "care-plans-review-or-approval",
        "display": "information exchange to support care plan review and approval"
    },
    {
        "code": "public-health-reporting",
        "display": "information exchange for public health reporting"
    },
    {
        "code": "payers-and-payments",
        "display": "information exchange for billing"
    },
    {
        "code": "break-the-glass",
        "display": "information exchange that requires break the glass"
    },
    {
        "code": "individual-practitioner",
        "display": "information exchange with an individual provider"
    },
    {
        "code": "individual-patient-member-consumer",
        "display": "information exchange with an individual patient-member-cleint-consumer"
    },
    {
        "code": "location",
        "display": "information exchange with a treating facility"
    },
    {
        "code": "group",
        "display": "information exchange with a provider group"
    },
    {
        "code": "department",
        "display": "information exchange with a provider department"
    },
    {
        "code": "no-reply",
        "display": "endpoint does not accept information for exchange"
    },
    {
        "code": "any-all",
        "display": "endpoint that accepts any and all types of information exchange possible for its endpoint type"
    } 
];
